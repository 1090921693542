import React, {useState, useEffect} from 'react';
import JaiwinNavbar from '../navbar/navbar';
import './notification.css'
import {Card, Container} from 'react-bootstrap';
import {GetEnquries} from "../AuthService/apiUrls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faMobileAlt} from "@fortawesome/free-solid-svg-icons";

function Notifications() {
    const [enquriesData, setEnquriesData] = useState([]);
    useEffect(() => {

        GetEnquries()
            .then(response => {
                setEnquriesData(response)
            })
            .catch(error => {
                console.error('Error:', error); // Handle errors if the Promise is rejected
            });

    }, []);

    return (
        <>
            <div className="row">
                <div className="col-lg-2">
                    <JaiwinNavbar/>
                </div>
                <div className="col-lg-10">
                    <Container>
                        <h3>Notification from customers</h3>
                         <div className="card-container">
                        {enquriesData.map(item => (
                            <Card key={item.id} className='m-2' style={{width: '18rem', marginBottom: '20px'}}>
                                <Card.Body>
                                    <Card.Title>
                                        {item.name} <span className="text-muted">({item.country})</span>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{new Date(item.date).toLocaleString()}</Card.Subtitle>
                                    <Card.Text>
                                        {item.description}
                                    </Card.Text>
                                    <Card.Link href={`tel:${item.phone}`}> <FontAwesomeIcon icon={faMobileAlt} style={{ width: 20,color: 'black' }} />{item.phone}</Card.Link>
                                    <Card.Link href={`mailto:${item.email}`}> <FontAwesomeIcon icon={faEnvelope} style={{ color: 'black' , margin:'0px 5px 0px 0px'}} />{item.email}</Card.Link>
                                </Card.Body>
                            </Card>
                        ))}
                              </div>
                    </Container>
                </div>
            </div>


        </>
    );

};


export default Notifications