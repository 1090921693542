import makeRequest from './api';


export const VerifyOTP = (userData) => makeRequest('POST', '/login', userData, false);
export const verifyEmail = (userData) => makeRequest('POST', '/admin/verify/email', userData, false);

export const refreshTokenAPI = (userData) => makeRequest('POST', '/login', userData, false);
export const GetEnquries = () => makeRequest('GET', '/enquiries', null, true);
export const CreateProduct = (userData) => makeRequest('POST', '/create_products', userData, true,false);
export const CreateUser = (userData) => makeRequest('POST', '/create_user', userData, true,true);

export const GetProducts = () => makeRequest('GET', '/products', null, true);
export const GetUsers = () => makeRequest('GET', '/users', null, true);

export const GetSpecificProduct = (id) => makeRequest('GET', `/products/${id}`, null, true);
export const GetSpecificUser = (id) => makeRequest('GET', `/users/${id}`, null, true);

export const DeleteSpecificProduct = (id) => makeRequest('DELETE', `/products/${id}`, null, true);
export const DeleteSpecificUser = (id) => makeRequest('DELETE', `/users/${id}`, null, true);


export const EditProduct = (userData,id) => makeRequest('PUT', `/update/products/${id}`, userData, true,false);
export const EditUser = (userData,id) => makeRequest('PUT', `/users/${id}`, userData, true,false);

