import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Send, Add} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {TextField, Card, CardMedia} from '@mui/material';
import sample from '../../sample.jpg'
import {CreateProduct, GetSpecificProduct, EditProduct} from "../AuthService/apiUrls";
import Textarea from "../emptytextarea";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "38%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: "90%",
    overflow: "auto",
};

export default function ProductModal({action, id,onProductUpdated}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [imageSrc, setImageSrc] = React.useState(null);

    const [name, setName] = React.useState('');
    const [businessType, setBusinessType] = React.useState('');
    const [origin, setOrigin] = React.useState('');
    const [brandName, setBrandName] = React.useState('');
    const [price, setPrice] = React.useState('');
    const [unit, setUnit] = React.useState('');
    const [color, setColor] = React.useState('');
    const [priceNote, setPriceNote] = React.useState('');
    const [preferredBuyer, setPreferredBuyer] = React.useState('');
    const [requireDescription, setRequireDescription] = React.useState('');
    const [fileImage, setFileImage] = React.useState(null);
    const [inputFields, setInputFields] = React.useState([]);

    const [message, setMessage] = React.useState('');

    const [snackBar, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');


    React.useEffect(() => {
        if (action === 'edit' && open) {
            GetSpecificProduct(id)
                .then(response => {
                    setImageSrc(response.image);
                    setName(response.name);
                    setBusinessType(response.business_type);
                    setOrigin(response.made_in);
                    setBrandName(response.brand_name);
                    setPrice(response.price);
                    setUnit(response.unit);
                    setColor(response.color);
                    setPriceNote(response.price_note);
                    setPreferredBuyer(response.preferred_buyer);
                    setRequireDescription(response.require_description);
                    setFileImage(null);
                    const inputFields = Object.keys(response.product_details).reduce((acc, key) => {
                        if (key.startsWith('label_')) {
                            const valueKey = key.replace('label_', 'value_');
                            acc.push({key: response.product_details[key], value: response.product_details[valueKey]});
                        }
                        return acc;
                    }, []);

                    setInputFields([...inputFields]);
                })
                .catch(error => {
                    console.error('Error:', error); // Handle errors if the Promise is rejected
                });
        }

    }, [action, id, open]);

    const handleSnackClick = (message, severity) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackBar(true);
        setTimeout(() => {
                setSnackBar(false);
            }, 5000);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
            setFileImage(file)
        }
    };

    const handleDynamicChange = (index, field, value) => {
        const newInputFields = [...inputFields];
        newInputFields[index][field] = value;
        setInputFields(newInputFields);
    };

    const handleChange = (field, value) => {
        switch (field) {
            case 'name':
                setName(value);
                break;
            case 'businessType':
                setBusinessType(value);
                break;
            case 'origin':
                setOrigin(value);
                break;
            case 'brandName':
                setBrandName(value);
                break;
            case 'price':
                setPrice(value);
                break;
            case 'unit':
                setUnit(value);
                break;
            case 'color':
                setColor(value);
                break;
            case 'priceNote':
                setPriceNote(value);
                break;
            case 'preferredBuyer':
                setPreferredBuyer(value);
                break;
            default:
                break;
        }
    };

    const handleTextareaChange = (value) => {
        setRequireDescription(value);
    };
    const addInputField = () => {
        setInputFields([...inputFields, {key: '', value: ''}]);
    };

    const removeInputField = (index) => {
        const newInputFields = [...inputFields];
        newInputFields.splice(index, 1);
        setInputFields(newInputFields);
    };
    var FormApi = CreateProduct
    if (action === 'edit') {
        FormApi = EditProduct
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!name || !businessType || !origin || !brandName || !price || !unit || !color || !priceNote) {
            setMessage('Please fill in all the fields');
            handleSnackClick('Please fill in all the fields', 'error');
            // Remove the message after 5 seconds
            setTimeout(() => {
                setMessage('');
            }, 5000);
            return;
        }
        // Create FormData object
        const formData = new FormData();

        // Append file input value
        formData.append('image', fileImage);

        // Append text field values
        formData.append('name', name);
        formData.append('business_type', businessType);
        formData.append('origin', origin);
        formData.append('brand_name', brandName);
        formData.append('price', price);
        formData.append('unit', unit);
        formData.append('color', color);
        formData.append('priceNote', priceNote);
        formData.append('preferredBuyer', preferredBuyer);
        formData.append('require_description', requireDescription);

        // Append inputFields values
        const jsonData = {};

        inputFields.forEach((field, index) => {
            jsonData[`label_${index + 1}`] = field.key;
            jsonData[`value_${index + 1}`] = field.value;
        });
        formData.append('product_details', JSON.stringify(jsonData));
        var FormApiData = formData
        if (action === 'edit') {
            FormApiData = formData
        }
        FormApi(FormApiData,id)
            .then(response => {
                setImageSrc(null);
                setName('');
                setBusinessType('');
                setOrigin('');
                setBrandName('');
                setPrice('');
                setUnit('');
                setColor('');
                setPriceNote('');
                setPreferredBuyer('');
                setRequireDescription('');
                setFileImage(null);
                setInputFields([]);
                handleClose()
                handleSnackClick(response.message, 'success');
            })
            .catch(error => {
                handleSnackClick(error, 'error');
                console.error('Error:', error); // Handle errors if the Promise is rejected
            });
        onProductUpdated();
    };

    return (
        <div style={{width: 30}}>
            <>
                {action === 'add' && (
                    <Button onClick={handleOpen} variant="contained" startIcon={<Add/>}>
                        Add
                    </Button>
                )}

                {action === 'edit' && (
                    <IconButton onClick={handleOpen} aria-label="edit">
                        <EditIcon/>
                    </IconButton>
                )}

            </>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Add Product
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': {m: 1, width: '45%'},
                        }}
                        noValidate
                        autoComplete="off">
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 3}}>
                            <Card sx={{maxWidth: 300}}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={imageSrc || sample}
                                    alt="green iguana"
                                />
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    size="large">
                                    <input hidden accept="image/*" type="file" onChange={handleImageUpload}/>
                                    <PhotoCamera fontSize="large"/>
                                </IconButton>
                            </Card>
                        </Box>

                        <TextField id="standard-basic" label="Name" variant="standard" required value={name}
                                   onChange={(e) => handleChange('name', e.target.value)}/>
                        <TextField id="standard-basic" label="Business Type" variant="standard" required
                                   value={businessType}
                                   onChange={(e) => handleChange('businessType', e.target.value)}/>
                        <TextField id="standard-basic" label="Origin" variant="standard" required value={origin}
                                   onChange={(e) => handleChange('origin', e.target.value)}/>
                        <TextField id="standard-basic" label="Brand Name" variant="standard" required
                                   value={brandName}
                                   onChange={(e) => handleChange('brandName', e.target.value)}/>
                        <TextField id="standard-basic" label="Price" variant="standard" required value={price}
                                   onChange={(e) => handleChange('price', e.target.value)}/>
                        <TextField id="standard-basic" label="Unit" variant="standard" required value={unit}
                                   onChange={(e) => handleChange('unit', e.target.value)}/>
                        <TextField id="standard-basic" label="Color" variant="standard" required value={color}
                                   onChange={(e) => handleChange('color', e.target.value)}/>
                        <TextField id="standard-basic" label="Price Note" variant="standard" required
                                   value={priceNote}
                                   onChange={(e) => handleChange('priceNote', e.target.value)}/>
                        <TextField id="standard-basic" label="Preferred Buyer From" sx={{width: "100%"}}
                                   variant="standard" required
                                   value={preferredBuyer}
                                   onChange={(e) => handleChange('preferredBuyer', e.target.value)}/>
                        <p style={{color: 'red'}}>{message}</p>
                    </Box>
                    <Box sx={{marginTop: 3}}>
                        <Typography variant="h6" component="h2">
                            Product Details
                        </Typography>

                        {inputFields.map((field, index) => (

                            <Box key={index} sx={{display: 'flex', alignItems: 'center', marginTop: 2}}>
                                <TextField
                                    label={`Label ${index + 1}`}

                                    variant="outlined"
                                    value={field.key}
                                    onChange={(e) => handleDynamicChange(index, 'key', e.target.value)}
                                    sx={{marginRight: 2}}
                                />
                                <TextField
                                    label={`Value ${index + 1}`}

                                    variant="standard"
                                    value={field.value}
                                    onChange={(e) => handleDynamicChange(index, 'value', e.target.value)}
                                    sx={{marginRight: 2, width: "50%"}}
                                />
                                <Button variant="outlined" onClick={() => removeInputField(index)}>
                                    Remove
                                </Button>
                            </Box>
                        ))}

                        <Button variant="outlined" onClick={addInputField} sx={{marginTop: 2}}>
                            Add Input Field
                        </Button>
                    </Box>
                    <Box sx={{marginTop: 3}}>
                        <Typography variant="h6" component="h2">
                            Product Description
                        </Typography>

                        <Textarea aria-label="empty textarea" minRows={3} placeholder="Product Description"
                                  value={requireDescription} onChange={(e) => {
                            handleTextareaChange(e.target.value)
                        }}/>
                    </Box>
                    <Button sx={{margin: 2}} variant="contained" startIcon={<Send/>} onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>
            <Box sx={{width: 500}}>

                <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        severity={snackSeverity}
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    )
        ;
}