import {React, useEffect, useState} from 'react';
import JaiwinNavbar from '../navbar/navbar';
import './product.css'
import {Container} from 'react-bootstrap';
import {Stack, CardMedia, Card, CardContent, Typography, Box, Grid} from '@mui/material';
import ProductModal from "./productModal";
import {DeleteSpecificProduct, GetProducts} from "../AuthService/apiUrls";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function Products() {
    const [refresh, setRefresh] = useState(false);
    const triggerRefresh = () => {
        console.log('refresh')
    setRefresh(!refresh); // Toggle the refresh state to re-trigger the effect
  };
    const [productsData, setProductsData] = useState([]);

    const handleDelete = (id) => {
        DeleteSpecificProduct(id)
            .then(response => {
                handleSnackClick(response.message, 'success');
            })
            .catch(error => {
                handleSnackClick(error.message, 'error');
                console.error('Error:', error); // Handle errors if the Promise is rejected
            });
        triggerRefresh()
    };

    useEffect(() => {

        GetProducts()
            .then(response => {
                setProductsData(response)
            })
            .catch(error => {
                console.error('Error:', error); // Handle errors if the Promise is rejected
            });

    }, [refresh]);

    const [snackBar, setSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    const handleSnackClick = (message, severity) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackBar(true);
        setTimeout(() => {
                setSnackBar(false);
            }, 5000);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };
    return (
        <>
            <Box sx={{width: 500}}>

                <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert
                        onClose={handleSnackClose}
                        severity={snackSeverity}
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <div className="row">
                <div className="col-lg-2">
                    <JaiwinNavbar/>
                </div>
                <div className="col-lg-10">
                    <Stack spacing={2} direction="row"
                           justifyContent="flex-end"
                           sx={{marginBottom: 5, marginTop: 3, marginRight: 10}}
                           alignItems="center">
                        <ProductModal action='add' onProductUpdated={triggerRefresh}/>

                    </Stack>
                    <Container>
                        <h3>Products</h3>
                        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                            {productsData.map((product, index) => (
                                <Grid item key={index}>
                                    <Card sx={{display: 'flex'}}>
                                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                            <CardContent sx={{flex: '1 0 auto'}}>
                                                <Typography variant="h4">
                                                    {product.name}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    <strong>Price : </strong>
                                                    {product.price}/{product.unit}
                                                    <span style={{
                                                        fontWeight: "lighter",
                                                        color: "#7b7e81",
                                                        fontSize: "smaller",
                                                        marginLeft: 2
                                                    }}>
                                                   ({product.price_note})
                                                    </span>
                                                </Typography>

                                                <Typography component="p" variant="h6">
                                                    Colors : <span style={{
                                                    fontWeight: "lighter",
                                                    color: "#7b7e81",
                                                    fontSize: "smaller",
                                                    marginLeft: 2
                                                }}>
                                                   {product.color}
                                                    </span>
                                                </Typography>

                                                <Typography component="p" variant="h6">
                                                    Made In : <span style={{
                                                    fontWeight: "lighter",
                                                    color: "#7b7e81",
                                                    fontSize: "smaller",
                                                    marginLeft: 2
                                                }}>
                                                   {product.made_in}
                                                    </span>
                                                </Typography>

                                            </CardContent>
                                            <Grid sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>

                                                <ProductModal action='edit' id={product.id} onProductUpdated={triggerRefresh}/>
                                                <IconButton onClick={() => handleDelete(product.id)}
                                                            aria-label="delete">
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            sx={{width: 150}}
                                            image={product.image}
                                            alt="Live from space album cover"
                                        />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </Container>
                </div>
            </div>


        </>
    );
};


export default Products