import {storeTokens, clearTokens} from './tokenFunctions';
import {refreshTokenAPI} from './apiUrls'; // Import your APIs

const AuthService = {
    handleAuth: async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (accessToken) {
            return true;
        } else {
            if (!refreshToken) {
                clearTokens();
                return false; // No refresh token, redirect to login
            }
            try {
                const response = await refreshTokenAPI(refreshToken);
                storeTokens(response.accessToken, refreshToken);
                return true; // Refresh successful, user authenticated
            } catch (error) {
                clearTokens();
                return false; // Refresh failed, redirect to login
            }

        }

    },

};

export default AuthService;
